import React from "react";

const Question = ({_question, onOptionChange, index}) => {
	var question = _question.text;

	question = question.replace(";", "<br />");
	question = question.replace(";;", "<br /><br />");

	return (
			<div className="question">
				<span>{index > 0 ? index + "." : null} </span>
				<span dangerouslySetInnerHTML={{__html: question}}></span>
				<div>
					<br/>
					{_question.options.map((option) => (
							<label key={option.id}>
								<input
										type="radio"
										name={`question_${_question.id}`}
										value={option.id}
										key={option.id}
										checked={option.selected}
										onChange={() => onOptionChange(_question.id, option.id)}
								/>
								{option.text}
							</label>
					))}
					<br/>
				</div>
			</div>
	);
};

export default Question;
