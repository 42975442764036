import "./App.css";
import React, {useState} from "react";
import html2pdf from "html2pdf.js";

import Question from "./components/Question";

var questions = [
	{
		id: 0,
		text: "Herr/Frau ...<br>Mein Name ist ... vom Verbraucher-Portal.<br>Ich habe nur 3 kurze Fragen an Sie. Es dauert maximal eine Minute.<br><br>Herr/Frau ...<br>damit Sie sich sicher sein können, dass es auch wirklich NUR um eine Befragung geht, würde ich ab jetzt das Gespräch aufzeichnen.<br><br>Ist das so für Sie in Ordnung?",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 1,
		text: "Herr/Frau..., meine erste Frage:<br>Gehören Sie in die Altersgruppe oder einer im Haushalt lebenden Personen?",
		options: [
			{id: 0, text: "21 – 52", selected: false},
			{id: 1, text: "53 – 63", selected: false},
			{id: 2, text: "64 – 69", selected: false},
			{id: 3, text: "70 - 83", selected: false},
		],
	},
	{
		id: 2,
		text: "So, Herr/Frau ..., es geht um das Thema Energie.<br><br>Haben Sie in den letzten 12 Monaten auch eine Preiserhöhung Ihres Energieanbieters bekommen?",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 3,
		text: 'Liegt ihr Stromverbrauch höher als 2.000 KW/h (also mehr als 50 € im Monat)?<br><br><span style="color:darkred">Bei kleiner 2.000 KW/h -> Feld Notizen "1"</span>',
		options: [
			{id: 0, text: "kleiner 2.000 KW/h", selected: false},
			{id: 1, text: "mehr als 2.000 KW/h", selected: false},
		],
	},
	{
		id: 4,
		text: 'Wohnen Sie zur Miete oder haben Sie ein Eigenheim (Haus)?<br><br><span style="color:darkred">Bei Miete -> Feld Notizen: "1"</span>',
		options: [
			{id: 0, text: "Miete", selected: false},
			{id: 1, text: "Eigenheim", selected: false},
		],
	},
	{
		id: 5,
		text: 'Immer mehr Menschen nutzen Solarzellen auf dem Dach. Haben Sie diese auch schon?<br><br><span style="color:darkred">Bei Ja -> Feld Notizen „1“<br>Bei Nein -> Projektauswahl: Photovoltaik</span>',
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 6,
		text: "So, Herr/Frau ... nun geht es um Fördermittel.<br><br>Wussten Sie, dass jedes Jahr 4,6 Milliarden Euro an Fördermittel und Subventionen für private Haushalte nicht abgerufen und somit verschenkt werden?",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 7,
		text: "Sind Sie sich sicher, dass für Ihren Haushalt alle Möglichkeiten von Fördermittel und Subventionen genutzt werden?",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 8,
		text: "Ist der Kunde älter als 64 und passt in die Umfrage EVITA/ AIO (90459 in Nürnberg)?",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 9,
		text: 'Herr/Frau ...,<br>da nur sehr wenige wirklich alle Möglichkeiten kennen, die Energiekosten drastisch zu reduzieren und alle Fördermittel abzurufen haben wir mit den Experten von Energie-Check und vertragsengel interessante Informationen für Sie zusammengestellt. Sie werden begeistert sein, wie viel Geld Sie sparen werden!<br><br>Herr/Frau ... ,;wenn es für Sie okay ist, wird Sie ein Kollege in den kommenden 2 Tagen dazu noch einmal anrufen.<br>Ist das in Ordnung für Sie?<br><br><span style="color:darkred">Bei Ja -> Zusätzliches Projekt: „Vertragsengel"<br>Rückruf gewünscht: "Ja"</span>',
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 10,
		text: "Es ist nur ein Gespräch per Telefon, niemand kommt zu ihnen nach Hause. Sie werden ein paar wichtige Informationen zu diesem Thema bekommen. Ich kann auch versprechen, dass es wird sich lohnen. Okay?<br><br><span style='color:darkred'>Bei Ja -> Rückruf gewünscht: 'Ja'</span>",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 11,
		text: "Herr/Frau ... , da nur sehr wenige wirklich alle Möglichkeiten kennen, die Energiekosten drastisch zu reduzieren, haben wir mit den Experten von Energie-Check interessante Informationen für Sie zusammengestellt. Sie werden begeistert sein, wie viel Geld Sie sparen werden!<br><br></br>Herr/ Frau .. , wenn es für Sie okay ist, wird Sie ein Kollege in den kommenden 2 Tagen dazu noch einmal anrufen. Ist das in Ordnung für Sie?<br><br><span style='color:darkred'>Bei Ja -> Rückruf gewünscht: 'Ja'</span>",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 12,
		text: "Es ist nur ein Gespräch per Telefon, niemand kommt zu ihnen nach Hause. Sie werden ein paar wichtige Informationen zu diesem Thema bekommen. Ich kann Ihnen auch versprechen, dass es es sich für Sie lohnen wird! Okay?<br><br><span style='color:darkred'>Bei Ja -> Rückruf gewünscht: 'Ja'</span>",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 13,
		text: "Sehr schön. Nun sind wir aber wirklich fertig.<br>Ich bedanke mich Herr/Frau ... für das angenehme Gespräch und dass Sie so nett zu mir waren und mitgemacht haben.<br><br>Dann wünsche ich Ihnen heute noch einen wunderschönen Tag und bleiben Sie gesund!",
		options: [{id: 0, text: "Umfrage beenden", selected: false}],
	},
	{
		id: 14,
		text: "So, Herr/Frau ..., nun geht es um gesundes Wohnen…<br><br>Leidet bei Ihnen im Haushalt jemand an Rückenschmerzen oder Schlafproblemen?",
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 15,
		text: 'Herr/Frau ..., wir sind auch schon fast fertig.<br>Unserer Partner die „EVITA/ AIO AG" möchte sich gerne etwas bekannter machen und deshalb werden unter allen Teilnehmern 20 hochwertige orthopädische Kopfkissen im Wert von 195 (EVITA)/ 89 (AIO) Euro verschenkt.<br><br>Ich kann ihnen zwar nichts versprechen, aber falls Sie ausgewählt werden, würde unserer Partner „EVITA/ AIO AG" Sie telefonisch darüber informieren. IST DAS IN ORDNUNG FÜR SIE?<br><br><span style="color:darkred">Bei Ja -> Rückruf gewünscht: "Ja"</span>',
		options: [
			{id: 0, text: "Ja", selected: false},
			{id: 1, text: "Nein", selected: false},
		],
	},
	{
		id: 16,
		text: "Gesprochen habe ich mit Herr/Frau ..., wohnhaft sind Sie in 90459 in Nürnberg. Ist das korrekt?<br><br>Ich bedanke mich Herr/Frau ... für das angenehme Gespräch und dass Sie so nett zu mir waren und mitgemacht haben.<br><br>Dann wünsche ich Ihnen heute noch einen wunderschönen Tag und bleiben Sie gesund!",
		options: [{id: 0, text: "Umfrage beenden", selected: false}],
	},
];

function App() {
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [displayedQuestions, setDisplayedQuestions] = useState([questions[0]]); // Start with the first question

	const nextQuestion = () => {
		console.log("Next Question...", currentQuestionIndex, displayedQuestions);
		var currentQuestion = displayedQuestions[displayedQuestions.length - 1];
		try {
			var selectedOptionId = currentQuestion.options.find(
					(opt) => opt.selected,
			).id;
		} catch (error) {
			console.log("No option selected");
			alert("Es wurde keine Option ausgewählt!");
			return;
		}

		console.log(
				`Current Question ID: ${currentQuestion.id}, Selected Option ID: ${selectedOptionId}`,
		);

		let nextIndex;

		switch (currentQuestion.id) {
			case 0: // init
				nextIndex = 1; // Goes to Q1
				console.log(`Next Index after Q0: ${nextIndex}`);
				break;
			case 1: // Age Group
				if (selectedOptionId == 0) nextIndex = 2; // "21 – 52" goes to Q2
				else if (selectedOptionId == 1) nextIndex = 2; // "53 – 63" goes to Q2
				else if (selectedOptionId == 2) nextIndex = 14; // "64 – 69" goes to Q9
				else nextIndex = 14; // "64 – 69" or "70 – 83" goes to Q14
				console.log(`Next Index after Q0: ${nextIndex}`);
				break;
			case 2: // Energy Price Increase
				nextIndex = 3; // Goes to Q3
				console.log(`Next Index after Q1: ${nextIndex}`);
				break;
			case 3: // Electricity Consumption
				if (selectedOptionId == 0 && (displayedQuestions[1].options[1].selected || displayedQuestions[1].options[2].selected)) {
					// "Less than 2000 KW/h"
					nextIndex = 11; // Go to Q11
}
				else if (selectedOptionId == 0) {
nextIndex = 6;
}else{
					// "More than 2000 KW/h"
					nextIndex = 4; // Go to Q4
				}
				break;
			case 4: // Lebenssituation (Miete/Eigenheim)
				if (selectedOptionId == 0 && displayedQuestions[1].options[2].selected) nextIndex = 11;
				else if (selectedOptionId == 0 && displayedQuestions[1].options[0].selected) nextIndex = 6;
				else if (selectedOptionId == 0 && displayedQuestions[1].options[1].selected) nextIndex = 11;
				else nextIndex = 5; // Goes to Q6
				break;
			case 5: // Solarzellen
				if (displayedQuestions[1].options[2].selected || displayedQuestions[1].options[1].selected) nextIndex = 11;
				else nextIndex = 6; // Goes to Q6
				break;
			case 6: // Fördermittel
				nextIndex = 7; // Goes to Q7
				break;
			case 7: // Möglichkeiten von Fördermittel
				nextIndex = 9; // Goes to Q8
				break;
			case 8: // Additional Information Consent
				nextIndex = currentQuestion.id == 0 ? 9 : 14; // If age 21-52, go to Q8, else end quiz
				break;
			case 9: // Zustimmung Photovoltaik + Fördermittel
				nextIndex = selectedOptionId == 0 ? 13 : 10; // Goes to Q9
				break;
			case 10: // Zustimmung erneut
				if (selectedOptionId == 1) nextIndex = -1;
				else if (displayedQuestions[1].options[2].selected) nextIndex = 2;
				else if (displayedQuestions[1].options[0].selected || displayedQuestions[1].options[1].selected) nextIndex = 13;
				else nextIndex = 14; // ask about back pains
				break;
			case 11:
				nextIndex = selectedOptionId == 0 ? 13 : 12; // Goes to Q11
				break;
			case 12: // Additional Information Consent 3
				nextIndex = 13;
				break;
			case 13: // Ende
				nextIndex = -1; // Ende der Umfrage
				break;
			case 14: // WOHNEN Rückenprobleme
				if (displayedQuestions[1].options[2].selected && selectedOptionId == 1) nextIndex = 2; // "64 – 69" goes to Q2
				else if (selectedOptionId == 0) nextIndex = 15;
				else nextIndex = -1; // End of the quiz
				break;
			case 15: // Abschluss
				if (displayedQuestions[1].options[2].selected) nextIndex = 2; // "64 – 69" goes to Q2
				else if (selectedOptionId == 0) nextIndex = 16;
				else nextIndex = -1; // End of the quiz
				break;
			case 16: // Abschluss
				nextIndex = -1;
				break;
			default:
				nextIndex = -1; // Safety default to end the quiz if something unexpected happens
				break;
		}

		try {
			if (nextIndex !== -1 && nextIndex < questions.length) {
				const newQuestionList = [...displayedQuestions, questions[nextIndex]];
				setDisplayedQuestions(newQuestionList);
				setCurrentQuestionIndex(nextIndex);
			} else {
				document.getElementsByClassName("endQuizMessage")[0].style.display =
						"inline-block";
				document.getElementsByClassName("endQuizMessage")[1].style.display =
						"inline-block";
			}
		} catch (error) {
			console.log(error);
			alert("Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut.");
		}

		currentQuestion = null;
		selectedOptionId = null;

		// Scroll to the very bottom of the page
		window.scrollTo(0, document.body.scrollHeight * 2);
	};

	const handleOptionChange = (questionId, selectedOptionId) => {
		const updatedQuestions = displayedQuestions.map((q) => {
			if (q.id == questionId) {
				return {
					...q,
					options: q.options.map((opt) => ({
						...opt,
						selected: opt.id == selectedOptionId,
					})),
				};
			}
			return q;
		});

		setDisplayedQuestions(updatedQuestions);
	};

	const saveResults = () => {
		// Prompt user for name
		const name = prompt(
				"Bitte geben Sie den Nachnamen des Kundens ein:",
				"Kunde",
		);

		// Get the root element after prompting for the name
		const element = document.getElementById("questions");

		// Generate a unique filename using the entered name and current date
		const filename = `${name}_${new Date().toISOString()}.pdf`;

		// Set the options for pdf generation
		const opt = {
			margin: 0.2,
			filename: filename,
			image: {type: "jpeg", quality: 0.98},
			html2canvas: {scale: 2},
			jsPDF: {unit: "in", format: "a4", orientation: "portrait"},
		};

		// Generate and save the PDF
		html2pdf().set(opt).from(element).save();
	};

	return (
			<div className="App">
				<header className="header">
					<h1>MULTI-KAMPAGNE</h1>
					<h2>Bettensystem/ Photovoltaik/ Fördermittel</h2>
				</header>
				<div id="questions">
					{displayedQuestions.map((question, index) => (
							<Question
									key={question.id}
									_question={question}
									onOptionChange={handleOptionChange}
									index={index}
							/>
					))}
				</div>
				<div
						className="endQuizMessage"
						style={{display: "none", fontWeight: "bolder"}}
				>
					Ende der Umfrage.
				</div>
				<br/>
				<button
						onClick={nextQuestion}
						id="button"
						style={{marginRight: "20px"}}
				>
					Weiter
				</button>
				<button
						className="endQuizMessage"
						style={{display: "none"}}
						onClick={saveResults}
						id="button"
				>
					Ergebnisse Speichern
				</button>
				<footer>
					<p>&copy; 2023 vertragsengel. Alle Rechte vorbehalten.</p>
				</footer>
			</div>
	);
}

export default App;
